<!--
 * @Description: 发票列表
 * @Author: ChenXueLin
 * @Date: 2021-09-24 15:16:41
 * @LastEditTime: 2022-07-15 16:13:57
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="项目ID"
                title="项目ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="项目名称"
                title="项目名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="负责人名称"
                title="负责人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="项目状态"
                title="项目状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <!-- <i class="e6-icon-export_line" title="导出"></i> -->
          <el-button type="primary" @click="addProject">普通开票申请</el-button>
          <el-button type="primary">预开票申请</el-button>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <el-dialog
        v-dialogDrag
        title="添加用户"
        :visible.sync="addUserVisible"
        width="500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="editCustomerDialogLoading"
        :element-loading-background="loadingBackground"
        custom-class="edit-customer-dialog"
      >
        <el-form
          ref="addUserForm"
          label-width="88px"
          :model="addUserForm"
          :rules="addUserRules"
        >
          <el-form-item label="用户名称" prop="corpName">
            <el-input
              v-model.trim="addUserForm.corpName"
              placeholder="请输入用户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="corpClass">
            <el-input
              v-model.trim="addUserForm.corpName"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户角色" prop="userCode">
            <e6-vr-select
              v-model="searchForm.typeId"
              :data="typeList"
              placeholder="用户角色"
              title="用户角色"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="所属企业" prop="userCode">
            <e6-vr-select
              v-model="searchForm.typeId"
              :data="typeList"
              placeholder="所属企业"
              title="所属企业"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="所属部门" prop="userCode">
            <e6-vr-select
              v-model="searchForm.typeId"
              :data="typeList"
              placeholder="所属部门"
              title="所属部门"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="职位" prop="corpClass">
            <el-input
              v-model.trim="addUserForm.corpName"
              placeholder="职位"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel">取消</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      total: 0,
      searchForm: {
        typeId: "",
        id: ""
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      activityData: [
        {
          id: 1,
          pid: 0,
          label: "根节点",
          disabled: true,
          children: [
            {
              id: 2,
              pid: 1,
              label: "支节点",
              disabled: true,
              children: [
                { id: 4, pid: 2, label: "叶子节点", disabled: false },
                { id: 5, pid: 2, label: "叶子节点", disabled: false }
              ]
            },
            {
              id: 3,
              pid: 1,
              label: "支节点",
              disabled: true,
              children: [
                { id: 6, pid: 3, label: "叶子节点", disabled: false },
                { id: 7, pid: 3, label: "叶子节点", disabled: false },
                { id: 8, pid: 3, label: "叶子节点", disabled: false },
                { id: 9, pid: 3, label: "叶子节点", disabled: false }
              ]
            }
          ]
        }
      ],
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户id",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "手机号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userType",
          display: true,
          fieldLabel: "用户类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "role",
          display: true,
          fieldLabel: "用户角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "company",
          display: true,
          fieldLabel: "所属企业",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "department",
          display: true,
          fieldLabel: "所属部门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "job",
          display: true,
          fieldLabel: "职位",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addUserVisible: false,
      addUserForm: {},
      editCustomerDialogLoading: false,
      addUserRules: {}
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "修改申请",
          id: 1
        },
        {
          label: "申请详情",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      // console.log(val, row);
      if (val.id == 2) {
        this.routerPush({
          name: "invoiceManage/invoiceDetail",
          params: {
            refresh: true
          }
        });
      }
    },
    addProject() {
      this.routerPush({
        name: "operationsCenter/addProject",
        params: {
          refresh: true
        }
      });
    },
    handleClose() {
      this.addUserVisible = false;
    }
  },

  created() {}
};
</script>
<style lang="scss" scoped></style>
